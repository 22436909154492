@import '../../style/responsive';

.spacer {
    background-position: center center;
    background-size: cover;
    height: 100vh;
    position: relative;
    width: 100vw;

    @include media-min(md) {
        background-attachment: fixed;
    }
}
