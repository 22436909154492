@import './variables';
@import './responsive';

* {
    box-sizing: border-box;
}

body {
    background-color: $background-color;
    color: $font-color;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    margin: 0;
    padding: 0;
}

a {
    border-bottom: 1px solid #889;
    color: $font-color;
    text-decoration: none;
    transition: all 0.3s;

    &:hover {
        border-bottom: 1px solid $font-color-highlight;
        color: $font-color-highlight;
    }
}

#root {
    height: 100vh;
    overflow-y: scroll;

    @include media-min(md) {
        scroll-snap-type: y mandatory;
    }
}

.section {

    @include media-min(md) {
        scroll-snap-align: start;
    }
}
