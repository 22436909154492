@import '../../style/responsive';
@import '../../style/variables';

.card {
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    max-width: calc(100vh - 20px);
    transform: rotate(-3deg);
    transition: all 0.3s;
    width: calc(100vw - 40px);

    @include media-min(sm) {
        max-width: 420px;
    }

    &:hover {
        transform: rotate(3deg);
    }

    &__image {
        border: 5px solid #000;
        display: none;
        flex-shrink: 0;
        width: 170px;

        @include media-min(sm) {
            display: grid;
        }

        img {
            width: 100%;
        }
    }

    &__info {
        margin-left: 20px;
        padding-bottom: 20px;

        li {
            margin-left: -20px;
        }
    }

    h1 {
        font-family: 'Josefin Slab', Helvetica, Arial, sans-serif;
    }
}
