@import '../../style/responsive';
@import '../../style/variables';

.contact {
    align-items: center;
    background-position: center center;
    background-size: cover;
    display: flex;
    justify-content: center;
    min-height: 100vh;
    position: relative;
    width: 100vw;

    @include media-min(md) {
        background-attachment: fixed;
    }

    &__box {
        background-color: $background-color-transparent;
        padding: 20px;

        width: calc(100vw - 40px);

        @include media-min(md) {
            left: 200px;
            max-width: 40vw;
            position: relative;
        }

        h2 {
            font-family: 'Josefin Slab', Helvetica, Arial, sans-serif;
            font-size: 1.5em;
            margin-top: 0;
        }
    }
}
