@import '../../style/variables';

.page-header {
    align-items: center;
    background-color: $background-color-transparent;
    display: flex;
    font-family: 'Josefin Slab', Helvetica, Arial, sans-serif;
    font-size: 2em;
    font-weight: bold;
    justify-content: center;
    padding: 10px;
    position: absolute;
    top: 0;
    width: 100vw;

    span {
        color: $font-color-highlight;
    }
}
