@import '../../style/variables';

.button-next {
    $this: &;
    align-items: center;
    background-color: rgba(255,255,255,0.3);
    border: 0;
    border-radius: 500px;
    bottom: 1em;
    cursor: pointer;
    display: flex;
    justify-content: center;
    left: calc(50% - 2em);
    outline: none;
    padding: 2em;
    position: absolute;
    transition: all 0.3s;
    z-index: 2;

    &:hover {
        background-color: rgba(255,255,255,0.5);
    }

    &__arrow {
        border-bottom: 0.5em solid #fff;
        border-right: 0.5em solid #fff;
        flex-shrink: 0;
        height: 1em;
        transform: rotate(45deg);
        transition: all 0.3s;
        width: 1em;

        #{$this}:hover & {
            border-color: $font-color-highlight;
        }
    }
}
