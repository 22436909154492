@import '../../style/variables';
@import '../../style/responsive';

.splash {
    align-items: center;
    background-image: linear-gradient(
        rgba($background-color-dark, 0) 70vh,
        $background-color-dark
    );
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    position: relative;
    width: 100vw;

    @include media-min(md) {
        background-attachment: fixed;
    }
}
