@import '../../style/variables';

.page-footer {
    align-items: center;
    background-color: $background-color-transparent;
    bottom: 0;
    display: flex;
    justify-content: center;
    padding: 10px;
    position: absolute;
    width: 100vw;

    span {
        color: $font-color-highlight;
    }
}
