$rg-screen-sm-min: 768px;
$rg-screen-md-min: 992px;
$rg-screen-lg-min: 1200px;

@mixin media-min($media) {

    @if $media == sm {

        @media (min-width: $rg-screen-sm-min) { @content; }
    }

    @else if $media == md {

        @media (min-width: $rg-screen-md-min) { @content; }
    }

    @else if $media == lg {

        @media (min-width: $rg-screen-lg-min) { @content; }
    }

    @else {

        @media (min-width: $media) { @content; }
    }
}
